/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"
import EventDetail from "../components/event-detail"
import SEO from "../components/seo"
import { useHistory } from "../hooks/use-history"

const EventTemplate = ({ data }) => {
  const event = data.event
  useHistory("event-history", "e/" + event.contentful_id)
  return (
    <React.Fragment>
      {event && (
        <SEO
          title={event.eventName}
          description={
            event.eventDescription && event.eventDescription.eventDescription
          }
          imageUrl={
            event.eventImage?.file?.url && event.eventImage.file.url + "?w=512"
          }
        />
      )}
      {event && <EventDetail event={event} />}
    </React.Fragment>
  )
}

export default EventTemplate

export const query = graphql`
  query($id: String!) {
    event: contentfulEvent(contentful_id: { eq: $id }) {
      contentful_id
      eventName
      eventImage {
        file {
          url
        }
      }
      organization {
        name
        email
        organizationNumber
        website
        phone
        address
        city
        state
        zip
        description {
          description
        }
        logo {
          file {
            url
          }
        }
        facebook
        twitter
        youtube
        instagram
        approved
        secretSlug
      }
      discipline {
        disciplineName
        image {
          file {
            url
          }
        }
      }
      experience {
        experienceName
        experienceIcon {
          file {
            url
          }
        }
      }
      venue {
        name
        address
        city
        state
        zip
        phone
        location {
          lon
          lat
        }
        neighborhood
        from
        to
        website {
          website
        }
        approved
      }
      eventStartDate
      eventEndDate
      from
      to
      allDayEvent
      timeOrDateExceptions
      ticketUrl {
        ticketUrl
      }
      eventOrVenueUrl {
        eventOrVenueUrl
      }
      eventPhoneNumber
      pricingType
      minPrice
      maxPrice
      submittedBy
      eventDescription {
        eventDescription
      }
      discountAvailable
      curatorInsight {
        curatorInsight
      }
      curator {
        name
        position
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
      collection {
        contentful_id
        name
        description {
          description
        }
        image {
          file {
            url
          }
        }
        discipline {
          disciplineName
          image {
            file {
              url
            }
          }
        }
        website {
          website
        }
        startDate
        endDate
        organization {
          name
          email
          organizationNumber
          website
          additionalContactInfo {
            additionalContactInfo
          }
          phone
          address
          city
          state
          zip
          logo {
            file {
              url
            }
          }
          description {
            description
          }
          facebook
          instagram
          twitter
          youtube
          approved
          secretSlug
        }
      }
    }
  }
`
